import React from "react";
import ProductImage from '../../partner-img/product_matrimony.jpeg';

export default function ProductPage() {
  return (
    <>
      <section className="product-page">
        <div className="banner">
          <h1>Our Products</h1>
        </div>
        <div className="container">
          <div className="product-info-container">
            <h2 className="product-heading">Smart Maheshwari Matrimonial App</h2>
            <div className="product-details">
              <img
                src={ProductImage}
                alt="Product"
                className="product-image"
              />
              <p className="product-description">
                The Smart Maheshwari Matrimonial App is the result of Miiscollp's dedication to creating innovative, user-focused solutions. Developed by a passionate team of professionals, the app seamlessly blends advanced AI technology with the deep-rooted traditions of the Maheshwari community. Miiscollp's goal was to design an app that not only simplifies the process of finding a life partner but also honors the cultural values that matter most to users.
                <br />
                Driven by innovation, security, and a commitment to delivering high-quality matchmaking experiences, the Miiscollp team has crafted a platform that is both intuitive and highly personalized. With privacy and user experience at the core, the app is a trusted companion for Maheshwari singles looking to form meaningful and lasting relationships.
              </p>
            </div>
            <div className="product-features-container">
              <ul className="product-features">
                <li><strong>Simple and Quick Sign-Up:</strong> Easy registration process with essential details.</li>
                <li><strong>Tailored Profiles:</strong> Complete profiles with personal and family information.</li>
                <li><strong>Define Your Ideal Partner:</strong> Set preferences for age, education, and more.</li>
                <li><strong>Upload Detailed Information:</strong> Add your biodata for more personalized matches.</li>
                <li><strong>Intelligent Matchmaking:</strong> AI-driven matching based on your preferences.</li>
                <li><strong>Receive Curated Matches:</strong> Get matches tailored to your criteria.</li>
                <li><strong>Safe and Private Conversations:</strong> Communicate securely and confidently.</li>
                <li><strong>Path to Lifelong Partnership:</strong> Build lasting connections with compatible partners.</li>
              </ul>
            </div>
            <button className="download-btn">Download App for Android</button>
          </div>
        </div>
      </section>
    </>
  );
}
