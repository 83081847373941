import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Css/Products.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  < React.StrictMode >
    {/* <Header /> */}
    {/* <HomePage/> */}
    {/* <About/> */}
    {/* <Testimonial/> */}
    {/* <MobileService/> */}
    {/* <AutomationService/> */}
    {/* <ManualService/> */}
    {/* <ApiService/> */}
    {/* <LoadPerformanceService/> */}
    {/* <SecurityService/> */}
    {/* <CompatibilityService/> */}
    {/* <UnitService/> */}
    {/* <CrossBrowserService/> */}

    <App />
    {/* <HomeFooter /> */}
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
