export const content = {
    //pageTitle: "Home",
    home: {
        buttonText: "Contact Us",
        heading: "Maheshwari Innovative IT Services - Web Development, Mobile Apps, Software Solutions, Digital Marketing",
        description: "Our over a decade’s experience of ceaselessly catering to clients with high retention substantiates our expertise as an ace software testing company. We ensure and deliver quality QA testing services to our clients.",
    },
    about: {
        buttonText: "Learn More About Miisco",
        heading: "Miisco - A software testing company with over a decade of experience in the field of quality assurance.",
        firstDescription: "Enhance the quality of your software with Miisco, a name in the software testing industry for over a decade. We provide a range of QA testing services, including customized validation and state of the art testing solutions. Our team consists of professionals who excel in data and software validation guaranteeing top notch quality assurance for all your projects. Trust Miisco, for software testing and QA services.",
        secondDescription: "At our QA company we give you the power to have control, over the software development process. This leads to productivity for your releases. We focus on providing cost solutions and delivering quality competitive products to customers in different industries regardless of the size of your business. With certifications we are dedicated, to speeding up application delivery while protecting our clients data and intellectual property rights. You can count on us for efficient quality assurance services.",
    },

    whyMiisco: {
        buttonText: "Connect With Us",
        heading: "Why Choose Miisco for Software Testing Services?",
        description: "At Miisco, we Take the Hassle Out of Software Quality Assurance. Our Extensive Range of QA and Software Testing Services are Designed to Ensure that Our Clients Deliver Exceptional Software Experiences to Their Users. With a Client Base Spanning Diverse Industries and Businesses of Varying Sizes, We Bring in Domain Experts who Offer Customized and Comprehensive Engineering Services. Our Goal is to Foster Long-lasting Client Relationships Built on Trust and Quality.",
        bullets: [
            "Comprehensive Testing Services for Superior Software Quality",
            "Guaranteeing Punctual and High-Quality Software Launches",
            "Certified Experts with Versatile Cross-Domain Expertise",
            "Proficiency in Testing Complex High-Load Solutions",
            "Implementing Multi-Layered Testing Approaches Encompassing Best Practices,",
            "Serving a Worldwide Clientele Spanning Diverse Industries",
        ]
    },

    services: {
        heading: "QA Automation & Testing Services",
        description: "Our highly skilled experts conduct comprehensive testing across various devices, offering testing services encompassing mobile, cloud, SOA, and more. As a premier software testing company, Miisco delivers an extensive suite of services tailored for the next generation of Artificial Intelligence and Machine Learning applications, ensuring superior quality and performance.",

    },

    contact: {
        heading: "Leave All Your Testing-Related Worries to Us!",
        description: "Our experts work ceaselessly to help you accelerate your delivery of quality software products. Just reach out to us by filling in the details.",
    },

    testimonial: {
        heading: "Our Work Speaks",
        description: "Our happy and satisfied clients are proof of our commitment and quality. Here are the testimonials from some of our clients who have been a part of our success story.",
    },

    qaClient: {
        heading: "Our Testing Experts are Certified",
    },

    project: {
        heading: "Our Projects",
        description: "Our automation journey has streamlined processes across industries using cutting-edge technology. We've reduced manual data errors with automated systems and enhanced accuracy in complex business logic, from finances to supply chains. Our commitment to innovation drives continued efficiency and excellence.",


        sacs: {
            title: "SACS",
            description: "We're proud to have successfully tested the Sacs website, dedicated to promoting psychological well-being. Our rigorous evaluation improved its user experience, enhancing tools and resources for a positive mental health journey.",
        },

        tgQa: {
            title: "TgQa",
            description: "Explore our comprehensive testing of TgQa, an E-commerce website, ensuring a seamless shopping experience for users.",
        },
        insurx: {
            title: "Insurx",
            description: "We conducted testing on Insurx, rigorously evaluating its intricate business logics and calculations to ensure reliability and accuracy.",
        },

    },

    workSector: {
        heading: "Empowering various sectors through technological advancements.",
        healthCare: {
            title: "Healthcare",
            description: "We optimize healthcare with technology and automation testing, ensuring seamless integration for heightened efficiency.",
        },
        realEstate: {
            title: "Real Estate",
            description: "Our expertise extends to performing automation testing specifically tailored for the real estate sector, ensuring seamless and efficient processes."
        },
        logistics: {
            title: "Logistics",
            description: "We specialize in precise automation testing for efficient and reliable logistics operations, ensuring streamlined and robust processes."
        },
        business: {
            title: "Business Automation",
            description: "Automating and streamlining business processes through innovative technological soultions is out ultimate aim.",
        },
        financial: {
            title: "Financial",
            description: "Our expertise extends to performing automation testing tailored for financial software, ensuring optimal performance and reliability.",
        },
        ecommerce: {
            title: "E-commerce",
            description: "Our expertise includes performing automation testing tailored for e-commerce websites, ensuring seamless functionality and optimize user experience."
        }

    }

    // blog: {
    //     heading: "Latest Blog From QA Experts",
    //     blogOne: {
    //         title: "How to Do Performace Testing Using JMeter?",
    //         description: "JMeter is one of the performance testing tools that can help expert to run performance and load testing for web applications. We have explained how you can create records/data and get the test report of your application..."
    //     },
    //     blogTwo: {
    //         title: "Security Vulnerabilities and Manual Prevention for Web Applications",
    //         description: "Security Testing is a type of software testing that uncovers vulnerabilities of the system. It also determines that the data and resources of the system are protected from possible intruders..."
    //     },
    //     blogThree: {
    //         title: "Essential Testing Metrics For Measuring Quality Assurance",
    //         description: "At Miisco, a software testing company, we use a variety of metrics to provide you with the latest tools and methods for the best possible output. Interested for QA Consulting? Contact us..."
    //     },

    // }


}
